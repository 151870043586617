// Import Alert
import Alert from './modules/alert';

// Menu
import Menu from './modules/menu';

import Accordion from './modules/accordion';

import Tabs from './modules/tabs';

// Import Font loader
import WebFont from 'webfontloader';

import Swiper from 'swiper';
import { Navigation, Autoplay, EffectFade } from 'swiper/modules';

export default {
  init () {
    // JavaScript to be fired on all pages

    // Load Fonts
    WebFont.load({
      classes: false,
      events: false,
      google: {
        families: ['Roboto:wght@300;400;700'],
        display: 'swap',
        version: 2,
      },
    });

    // Alert
    if (document.getElementById('tofino-notification')) {
      Alert();
    }

    // Menu
    if (document.getElementById('menu-primary-navigation')) {
      Menu();
    }
    
    // Accordion
    if (document.getElementsByClassName('accordion')) {
      Accordion();
    }
    
    // Tabs
    if (document.getElementById('sub-tabs')) {
      Tabs();
    }

    var headerElement = document.querySelector('header');
    document.addEventListener("scroll", (event) => {
      if(window.scrollY > 115) {
        headerElement.classList.add('scrolled')
      } else {
        headerElement.classList.remove('scrolled')
      }
    });


  const swiper = new Swiper('.swiper', {
      // configure Swiper (hero scroller) to use modules
      modules: [Navigation, Autoplay, EffectFade],
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      speed: 400,
      loop: true,
      effect: "fade",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });


  },
  finalize () {
    // JavaScript to be fired after init
  },
  loaded () {
    // Javascript to be fired once fully loaded
  },
};
