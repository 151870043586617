const Tabs = () => {
  let navLinks = document.querySelectorAll('#sub-tabs nav a')

  //if there's a hash then call selectTab()
  if(window.location.hash) {
    selectTab(window.location.hash)
  }
  
  navLinks.forEach(link => {
    link.addEventListener('click', (el) => {
      el.preventDefault()

      //call selectTab() with clicked hash
      let hash = el.target.hash
      selectTab(hash)
    })
  })
  
  function selectTab(hash) {
    hash = hash.replace('#', '');

    //when clicked remove current for all links
    navLinks.forEach(link => {
      link.classList.remove('current')
      link.classList.add('default')
    })
      
    let currentLink = document.querySelector('[data-link="' + hash + '"]')
    // //amend classes for clicked link
    currentLink.classList.add('current')
    currentLink.classList.remove('default')

    // hide all tab content on click
    let tabItems = document.querySelectorAll('#tab-items > div')
    tabItems.forEach(el => {
      el.classList.add('hidden')
    })
  
    //only show active item
    let activeContent = document.getElementById(hash)
    activeContent.classList.remove('hidden')
  }
}

export default Tabs;
